@import "./variables";

html {
    font-size: 14px;
}

iframe {
    width: 100%;
    border: 0;
}

#root, #root > div {
    position: relative;
    height: 100%;
}

.navbar-inverse {
    background-color: $theme-color;
    border-color: $theme-color;
}

.utmenu-list > li > a {
    color: #B2BBBD;
    &.active {
        background-color: $theme-color;
    }
}

.btn-round {
    border-radius: 5px;
}

.login-second-page {
    border-color: $theme-color;
}

.main-content {
    position: relative;
    min-height: -webkit-calc(100% - #{$footer-height});
    min-height: calc(100% - #{$footer-height});
}

.main-footer {
    height: auto;
}

.content-login {
    position: relative;
    top: -$menu-height;
}

.is_primary_residence_wrapper {
    margin-top: 15px;
}

.react-datepicker__input-container {
    display: block;
}

.page-header .breadcrumb {
    float: none;
    padding: 0;
    margin-top: -20px;

    & > li + li:before {
        color: #424242;
    }

    li {
        font-size: 0.4em;

        a {
            color: #424242;
        }
    }
}

.Select-menu-outer {
    z-index: 10;
}

.form-select {
    display: inline-block;
    width: auto;
    vertical-align: middle;
    min-width: 200px;
}

.panel-not-expandable {
    &:before, &:after {
        display: none;
    }
}

.panel-model-expandable .weight {
    display: block;
    float: right;
    margin-right: 15px;

    span:first-child {
        padding-right: 15px;
    }
}

.slice {
    transition: opacity 0.3s ease;
}

.sb-details {
    font-size: 16px;
    pointer-events: none;
}


.visible-overflow {
    overflow: visible;
}

div.dataTables_wrapper div.dataTables_filter input {
    min-width: 200px;
    max-width: 200px;
    height: 36px;
    font-size: 14px;
}

.graph-color {
    display: inline-block;
    width: 20px;
    height: 14px;
    margin-right: 10px;
    position: relative;
    top: 1px;
    border: 1px solid #000;
}

.panel-permissions {
    .col-sm-2:nth-child(6n + 1) {
        clear: left;
    }

    h5, label, p {
        text-transform: capitalize;
    }
}

.dropdown-absolute {
    position: static;

    .dropdown-menu {
        top: initial;
    }
}

.panel {
    .panel-actions {
        z-index: 0;
    }
}

.panel-actions {
    .btn + .btn {
        margin-left: 10px;
    }
    a {
        vertical-align: top;
    }
    a + div {
        margin-left: 10px;
    }
}

.webui-popover-content .hidden {
    display: block !important;
}

.fa-spin {
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear
}

.fa-pulse {
    -webkit-animation: fa-spin 1s infinite steps(8);
    animation: fa-spin 1s infinite steps(8)
}

@-webkit-keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

@keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

.chart-wrapper {
    height: 300px;
    width: calc(100% - 20px);
    padding: 10px;
}

.with-copy {
    .form-control {
        width: calc(100% - 85px);
        display: inline-block;
    }

    .btn {
        margin-left: 2px;
    }
}

.form-actions .btn + .btn {
    margin-left: 5px;
}

.dropzone-wrapper > div {
    width: 100% !important;
    height: auto !important;
    border: 0 !important;
    border-radius: 0 !important;
    cursor: pointer;
}

.public-view {
    padding-top:80px;

    .public-bar {
        background: #fff;
        height: 80px;
        min-height: 80px;

        .navbar-header {
            height: 80px;
        }

        .main-header .navbar-brand {
            height: 80px;
        }

        .navbar-brand-logo {
            height: 42px;
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

.page-subtitle {
    clear: both;
    text-align: left;
}

.with-actions {
    max-width: calc(100% - 90px);
}

.hide-footer .main-footer {
    display: none;
}

.form-wizard.wizard-vertical > ol {
    width: 14%;
}

.form-wizard.wizard-vertical > div {
    width: 84%;
}

@media (min-width: 768px){
    .form-wizard.wizard-vertical.custodial-request {
        & > ol {
            width: 32%;
        }

        & > div {
            width: 68%;
        }
    }
}


@media (min-width: 992px){
    .form-wizard.wizard-vertical.custodial-request {
        & > ol {
            width: 25%;
        }

        & > div {
            width: 75%;
        }
    }
}

@media screen and (min-width: 1200px){
    .form-wizard.wizard-vertical.custodial-request {
        & > ol {
            width: 20%;
        }

        & > div {
            width: 80%;
        }
    }
}

.bwizard-activated {
    height: auto;
    overflow: visible;
    min-height: 150px;
}

.pager.bwizard-buttons .disabled {
    display: none;
}

.btn-download {
    display: inline-block;
    margin: 15px 0 0;
    text-decoration: underline;
}

.quick-links a {
    width: 100%;
    padding: 20px;
    border: 1px solid #e8e8e8 !important;
    border-radius: 5px;
    background: #f7f7f7;
    transition: background-color 0.3s, color 0.15s, box-shadow 0.3s, opacity 0.3s;

    &:hover {
        cursor: pointer;
        background: #f5f3f3;
        box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.25);
    }

    a {
        padding: 0;
        border: 0 !important;
        
        &:hover {
            box-shadow: none;
            background: transparent;
        }
    }

    span.pull-right {
        margin-top: 1px;
    }
}

.form-control.no-height {
    height: auto;
}

.panel-actions .btn-export {
    color: #2AA57F;
    box-shadow: inset 0 0 0 1px #2AA57F;
    transition: color 0.25s ease, background-color 0.25s ease, box-shadow 0.25s ease;

    &:hover {
        background-color: #46A87F;
        color: #fff;
        box-shadow: none;
    }
}

.synced {
    color: #57a559;
}

.react-datepicker-popper {
    z-index: 3 !important;
}

.cash-reserve-table {
    table.dataTable.nowrap {
        th:first-of-type, td:first-of-type {
            max-width: 300px;
            white-space: normal;
        }
    }
}

.no-icon:before {
    display: none;
}

span.react-numeric-input b {
   display: none;
}

span.input-group-addon.dollar-sign {
    background: rgba(0,0,0,0);
    background-image: linear-gradient(#36a9e1,#36a9e1),linear-gradient(#e0e0e0,#e0e0e0);
    border: 0;
    border-radius: 0;
    background-repeat: no-repeat;
    background-position: center bottom,center calc(100% - 1px);
    background-size: 0 2px,100% 1px;
    transition: background 0s ease-out;

    &.focused {
        background-size: 100% 2px,100% 1px;
        outline: 0;
        transition-duration: .3s;
    }
}

label.toggle-label span, .react-toggle {
    vertical-align: middle;
}

label.toggle-label span {
    margin: 0 8px;
    font-weight: normal;
}

.edit-form-function > div {
    width: calc(100% - 115px);
    display: inline-block;
    vertical-align: middle;
}

.disabled-input {
    border-bottom: 1px solid #e0e0e0;
    color: #555;
    height: 34px;
    padding: 0;
    font-size: 14px;
    line-height: 2.428571;
}

.popover-list ul {
    padding-left: 20px;
}

.ReactTable {
    &.scroll {
        .rt-table {
            overflow: auto;
        }
    }
    .rt-table, .rt-tbody, .rt-td, .rt-th {
        overflow: visible;
    }
}

span.title-link {
    color: #36A9E1;
    cursor: pointer;
}

.proposal-actions {
    top: 25px;
    z-index: 2;
}

.implement-header {
    position: relative;
    width: 100%;
    overflow: hidden;

    h5 {
        color: #849397;
        font-size: 0.8em;
        text-shadow: none;
    }

    .col-step {
        padding: 0;
    }

    .cursor-pointer {
        display: table;
        margin: 0 auto;
    }

    .step {
        display: table;
        margin: 15px auto 0;
        position: relative;
        z-index: 1;
        width: 100%;

        &:after {
            content: '';
            position: absolute;
            display: block;
            background: #DFE3E4;
            width: 100%;
            height: 0.5em;
            top: 1.25em;
            left: 50%;
            margin-left: 1.5em\9;
            z-index: -1;
        }
    }

    .col-step:last-of-type .step:after {
        display: none;
    }

    .circle {
        width: 3em;
        height: 3em;
        background: #DFE3E4;
        border-radius: 50%;
        margin: auto;

        &.active {
            background: #3498DB;

            & + h5 {
                color: #3498DB;
            }
        }
    }
}
.impersonate-alert {
    position: fixed;
    top: 0;
    left: 0;
    font-size: 0;
    z-index: 10000;

    &:before {
        content: "";
        display: block;
        width: 30px;
        height: 30px;
        background: #fcf8e3;
        transform: rotate(45deg);
        top: -15px;
        left: -15px;
        position: relative;
    }

    &:hover {
        font-size: 14px;
        line-height: 1.5;
        @media (min-width: 768px) {
            padding: 10px 0;
        }
        div {
            color: #8a6d3b;
            background: #fcf8e3;
            padding: 8px 10px;
            border-radius: 4px;
            border: 1px solid #faebcc;
        }
    }

    &:hover:before {
        display: none;
    }
}

#BillingGroupForm + #BillingDefinitionForm {
    left: -8px;
}

.cash-management, .legacy-holdings, .unassigned-securities, .income-builder, .directed-trades {
    .account, .category, .description, .sector, .normal {
        white-space: normal;
    }

    .rt-th {
        &.-cursor-pointer {
            &:after {
                font-family: 'Glyphicons Halflings';
                opacity: 0.2;
                content: "\e150";
                position: absolute;
                right: 2px;
                top: 5px;
            }

            &.-sort-asc, &.-sort-desc {
                box-shadow: none;
            }

            &.-sort-asc:after {
                content: "\e155";
                opacity: 0.5;
            }

            &.-sort-desc:after {
                content: "\e156";
                opacity: 0.5;
            }
        }
    }
}

.directed-trades {
    margin-bottom: 25px;
}

.webui-popover.in {
    z-index: 999999;
}

.proposal-inventories .ReactTable {
    .rt-table {
        overflow: auto;
    }

    .rt-tbody {
        overflow: auto;
    
        .rt-td div {
            max-width: 100%;
            min-height: 24px;
            display: block;
            white-space: pre-wrap;
            padding: 2px;
        }
    }
}

.monitor-filter {
    background: #f9f9f9;
    border: 1px solid #f9f9f9;
    border-radius: 4px;
    padding: 15px 15px 0;
    margin-bottom: 15px;
    
    .rc-slider-disabled {
        background-color: transparent;
    }

    .rc-slider-disabled .rc-slider-track {
        background-color: #e9e9e9;
    }

    .form-select {
        min-width: 100%;
    }
    
    &.monitor-filter-toggles .form-group {
        margin-bottom: 11px;
    }
}

.banners {
    .banner {
        line-height: 24px;
        padding: 15px;
        border: 1px solid transparent;
        border-radius: 4px;
    }

    .banner.banner-success {
        color: #3c763d;
        background-color: #dff0d8;
        border-color: #d6e9c6;
    }

    .banner.banner-danger {
        color: #a94442;
        background-color: #f2dede;
        border-color: #ebccd1;
    }

    .banner.banner-warning {
        color: #8a6d3b;
        background-color: #fcf8e3;
        border-color: #faebcc;
    }

    a {
        cursor: pointer;
    }
}

.has-error {
    .input-group .form-control {
        color: #a94442;
    }

    .Select.is-focused:not(.is-open) > .Select-control {
        border-color: #a94442;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(169, 68, 66, 0.1);
    }

    .Select-control {
        border-color: #a94442;
    }

    .Select.has-value.Select--single > .Select-control .Select-value .Select-value-label, .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
        color: #a94442;
    }

    .Select-arrow {
        border-color: #a94442 transparent transparent;
    }
}

.income-builder .ReactTable .rt-tbody {
    max-height: 725px;
    overflow: auto visible;
}

.truncate-note {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    color: #36A9E1;
}

.truncate-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.color-picker-color {
    width: 100%;
    height: 24px;
    border-radius: 2px;
}

.color-picker-swatch {
    padding: 5px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
    display: block;
    cursor: pointer;
}

.color-picker-popover {
    position: absolute;
    z-index: 2;
}

.color-picker-cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.inline-input {
    .control-radio {
        display: inline-block;
    }
    
    .control-radio + .control-radio {
        margin-left: 15px;
    }
    
    .control-label {
        width: 100%;
    }
}

.form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0; // Override base .form-check
    margin-right: .75rem;

    .control {
        display: inline-block;
    }
}

.monitor-view-selector {
    margin-bottom: 15px;
}

.security-field-group {
    .control-label {
        display: none !important;
    }

    .btn-default {
        margin-top: 0 !important;
    }

    .form-group:first-of-type {
        .control-label {
            display: block !important;
        }

        .btn-default {
            margin-top: 25px !important;
        }
    }

    .security-field {
        margin: 0 15px 15px 0;
    }
}

.security-field-section {
    min-height: .01%;
    overflow-x: hidden;

    @media (min-width: 768px) {
        overflow-x: auto;
    }

    .security-fields {
        display: flex;
        flex-direction: column;

        @media (min-width: 768px) {
            flex-direction: row;
            width: 2400px;
        }
    
        @media (min-width: 1200px) {
            width: 2500px;
        }
    }

    .security-field {
        width: 100%;
        flex: auto;

        @media (min-width: 768px) {
            width: 33.33333333%;
        }
    
        @media (min-width: 1200px) {
            width: 16.66666667%;

            &:first-child {
                width: 33.33333333%;
            }
        }
    }

    .remove-field {
        width: 100%;
        margin: 0 15px 15px 0;
        flex: auto;

        @media (min-width: 768px) {
            width: 8.33333333%;
        }
    }
}


.regional-allocation {
    width: 100%;
    margin: -30px auto -55px;
    text {
        font-size: 30px;
        font-family: 'Raleway', sans-serif;
    }

    @media (min-width: 768px) {
        margin: -40px auto -75px;
        width: 75%;
        text {
            font-size: 20px;
        }
    }

    @media (min-width: 1200px) {
        margin: -60px auto -110px;
        text {
            font-size: 16px;
        }
    }

    @media (min-width: 1440px) {
        width: 66%;
        margin: -75px auto -125px;
    }
}

.rd3-sector-exposure {
    .chart-wrapper {
        overflow-x: auto;
        width: 100%;
        height: initial;
    }
    .rd3-legend-chart {
        div > div:nth-child(2) {
            display: block !important;
            width: 225px !important;
            position: absolute;
            right: 0;
            top: 0;
        }
        ul.rd3-legend {
            width: 225px !important;
        }
    }
}

.critical-path-ad-only .panel-title {
    font-weight: normal;
    text-transform: none;
}

.badge-pill {
    padding-right: .6em;
    padding-left: .6em;
    border-radius: 10rem !important;
}

.legacy-security-popover + .truncate-text, .legacy-security-popover + .hidden + .truncate-text {
    vertical-align: middle;
    display: inline-block;
}

.ending-cash-container {
    position: relative;
    padding: 45px 0;
    margin-bottom: 45px;
    z-index: 10;

    .ending-cash-line {
        display: inline-block;
        background-color: #46a87f;
        width: 100%;
        height: 3px;
        border-radius: 10px;
        box-shadow: 0 0 5px #adafae;
        position: absolute;
        top: 57px;

        &.ending-cash-lower, &.ending-cash-upper {
            background-color: #a94442;
        }

        &.ending-cash-lower {
            left: 0;
        }

        &.ending-cash-upper {
            right: 0;
        }
    }

    .ending-cash-circle {
        background-color: #646464;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        position: absolute;
        top: 51px;
        left: 0;
        transition: left 0.5s ease;
        transform: translateX(-7.5px);

        &.ending-cash-lower-threshold {
            left: 25%;
        }

        &.ending-cash-upper-threshold {
            left: 75%;
        }

        &.ending-cash-starting {
            background-color: #b94133;
        }

        &.ending-cash-ending {
            background-color: #52a030;
        }
    }

    .ending-cash-tooltip {
        position: absolute;
        border-radius: 5px;
        display: inline-block;
        font-size: 13px;
        padding: 4px 8px;
        pointer-events: none;
        color: #4b4a4a;
        background: rgb(230, 230, 230);
        border: 1px solid rgb(214, 214, 214);
        transition: left 0.5s ease;
        transform: translateX(-50%);
        text-align: center;

        &:after {
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            content: "";
            display: block;
            width: 0; 
            height: 0; 
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
        }

        &.above {
            top: 0px;
            width: 110px;
            &:after {
                bottom: -5px;
                border-top: 10px solid rgb(230, 230, 230);
            }
        }

        &.below {
            top: 71px;

            &:after {
                top: -5px;
                border-bottom: 10px solid rgb(230, 230, 230);
            }
        }
    }
}

.table-responsive .table-margin-bottom {
    margin-bottom: 140px !important;
}

.menu-outer-top .Select-menu-outer {
    bottom: 35px;
    top: initial;
}

.select-open-up tr:nth-last-child(-n+3):not(:nth-child(1)):not(:nth-child(2)) {

    .Select-menu-outer {
        top: auto;
        bottom: 100%;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        border-bottom-right-radius: unset;
        border-bottom-left-radius: unset;
    }

    .Select-option:first-child {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
    }

    .Select-option:last-child {
        border-bottom-right-radius: unset;
        border-bottom-left-radius: unset;
    }

    .Select.is-open > .Select-control {
        border-top-right-radius: unset;
        border-top-left-radius: unset;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
    }
}

.trade-row .trade-row-desc {
    .truncate-text {
        max-width: 100% !important;
    }

    @media (max-width: 1441px){
        .truncate-text {
            max-width: 550px !important;
        }
    }

    @media (max-width: 1025px){
        .truncate-text {
            max-width: 455px !important;
        }
    }

    @media (max-width: 993px){
        .truncate-text {
            max-width: 435px !important;
        }
    }
    
    @media (max-width: 769px){
        .truncate-text {
            max-width: 250px !important;
        }
    }
}


.form-control.target-order {
    white-space: normal;
}

@media (min-width: 992px){
    .modal-dialog.modal-lg {
        width: 900px;
    }
}

@media screen and (min-width: 1200px){
    .modal-dialog.modal-lg {
        width: 1100px;
    }
}

@media screen and (min-width: 1600px){
    .modal-dialog.modal-lg {
        width: 1400px;
    }
}

.billing-summary {
    thead tr {
        background: #fff;
        position: sticky;
        top: 0;
        z-index: 3;

        th:first-child {
            background: #fff;
            position: sticky;
            left: 0;
        }
    }

    .billing-history-filter td:first-child {
        position: absolute;
        z-index: 2;
        padding-right: 20px;
        padding-left: 5px;
    }

    td:first-child {
        background: #f9f9f9;
        position: sticky;
        left: 0;
        z-index: 1;
    }

    &.table-striped > tbody > tr:nth-of-type(even) td:first-child {
        background-color: #fff;
    }

    &.table-hover > tbody > tr:hover td:first-child {
        background: #eee;
    }
    
    @media (max-width: 991px){
        td:first-child, th:first-child {
            max-width: 25em;
            overflow: auto;
        }
    }

    
    @media (max-width: 767px){
        td:first-child, th:first-child {
            max-width: 10em;
        }
    }
}  


.unselected-managed-accounts {
    li > div {
        display: flex;
        & > span {
            flex-grow: 1;
        }
        & > div {
            min-width: 195px;
        }
    }
}